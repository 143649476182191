import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img43 from './images/shutterstock_1081128197-min.jpg'
import styles from './post-grid.module.css'

const AreSweetenersHarmfulForHealth = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Chcete zhubnout rychle? Nedělejte to'}
        description={
          'Honem rychle se snažíte zhubnout před dovolenou, svatbou kamarádky nebo jinou důležitou událostí a pouštítě se kvůli tomu do extrémních diet? Není to úplně dobrý nápad. Rychlé hubnutí pomocí drastických kroků může mít spoustu negativních dopadů. My si dnes povíme, jaké to jsou.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/are-sweeteners-harmful-for-health">
            Škodí sladidla našemu zdraví?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Škodí sladidla našemu zdraví?" date="09.06.2023" />
            <div>
              <p className={styles.postText}>
                Náhražky cukru se hojně využívají, ať už do různých potravin a
                produktů, tak v domácnostech. Sladidla jsou lákavou{' '}
                <b>alternativou </b>
                především pro osoby, které se snaží{' '}
                <b>omezit cukr a také příjem kalorií</b>. Též je využívají
                <b> diabetici</b>. Zdá se tedy, že jsou{' '}
                <b>slibným pomocníkem při cestě za zdravým životním stylem</b>.
                Nebo ne? Mohou mít sladidla vedlejší účinky nebo dokonce{' '}
                <b> špatný vliv na naše zdraví</b>?
              </p>

              <img
                src={img43}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <p className={styles.postText}>Co jsou sladidla?</p>

              <p className={styles.postText}>
                <b>Náhradní sladidla</b> jsou <b>potravinářské přísady</b>,
                které mohou mít větší nebo menší chuťový efekt než cukr, ale
                většinou mají <b>menší množství energie</b>. Využívají se za
                účelem dodat pokrmům a nápojům sladkou chuť. Přídavné jméno
                náhradní se používá z toho důvodu, že{' '}
                <b>samotným sladidlem je i běžný cukr</b>. Ten patří mezi
                přírodní sladidla, stejně jako například glukózový sirup,
                karamel nebo med. Dnes se tedy budeme bavit především o
                sladidlech <b>náhradních</b>, protože právě ta mají za úkol
                nahradit cukr.
              </p>
              <p className={styles.postText}>
                <b>TIP</b>:{' '}
                <span className={styles.author}>
                  Jsou sirupy zdravou náhražkou cukru? Přečtěte si
                </span>{' '}
                <a href="https://nutritionpro.cz/blog/sirupy/" target="_blank">
                  zde
                </a>
                .
              </p>
              <p className={styles.postText}>Rozdělení sladidel</p>
              <p className={styles.postText}>
                Sladidla můžeme rozdělovat podle několika faktorů. Základní
                rozdělení je na <b>sladidla přírodní a syntetická</b>, tedy{' '}
                <b>uměle vytvořená</b>.{' '}
              </p>
              <p className={styles.postText}>
                <b>Přírodní sladidla</b> jsou ve vodě rozpustné sladce
                chutnající látky na bázi přírodních sacharidů, to znamená, že
                jsou získána z přírodních zdrojů. Jak už jsme si řekli, je to
                například cukr (sacharóza), ale také <b>fruktóza</b>,{' '}
                <b>glukóza</b> nebo <b>steviosid</b>.
              </p>
              <p className={styles.postText}>
                <b>Umělá sladidla</b> jsou vytvořena synteticky. Mají výrazně{' '}
                <b>vyšší sladivost</b> než běžný cukr a{' '}
                <b>nenosí energetickou hodnotu</b>. Jedná se například o
                aspartam, acesulfam K, sukralosu, sacharin a cyklamát.
              </p>
              <p className={styles.postText}>
                <b>Syntetická sladidla, identická s přírodními</b> jsou
                například <b>sorbitol, xylitol a manitol</b>. Jedná se o cukrené
                alkoholy.
              </p>
              <p className={styles.postText}>
                Další rozdělení je na sladidla <b>kalorická a nekalorická</b>.
              </p>
              <p className={styles.postText}>
                <b>Kalorická</b>, neboli <b>energetická</b>, mají{' '}
                <b>vysokou energetickou hodnotu</b> a{' '}
                <b>sladivost přibližně srovnatelnou se sacharózou</b>, čili
                cukrem. To znamená, že když chcete <b>nahradit cukr</b> nějakým
                kalorickým sladidlem, měli byste použít zhruba{' '}
                <b>stejnou dávku</b>. Kromě sacharózy sem patří třeba škrobové
                sirupy (glukózový, fruktózo-glukózový, glukózo-fruktózový),
                inulin nebo cukerné alkoholy (erythritol). Sladivost{' '}
                <b>cukerných alkoholů</b> je 50 - 100 % sacharózy, jejich
                výhodou je však to, že minimálně ovlivňují glykémii (krevní
                cukr) a nezpůsobují zubní kaz. U některých osob však mohou
                způsobit střevní diskomfort.
              </p>
              <p className={styles.postText}>
                <b>Nekalorická</b>, neboli <b>nízkoenergetická</b> sladidla,
                mají, oproti energetickým, mnohonásobně <b>vyšší sladivost</b>{' '}
                než sacharóza a <b>žádnou energetickou hodnotu</b>. Patří sem
                například acesulfam-K, aspartam, cyklamát, sacharin, sukralóza
                nebo steviol-glykosid (stévie). Pro představu,{' '}
                <b>sladivost aspartamu je 180 - 200</b> (oproti sacharóze = 1).
              </p>
              <p className={styles.postText}>
                Jak vidíte, většina{' '}
                <b>nízkoenergetických sladidel je syntetického původu</b> a
                většina <b>energetických přírodního původu</b>.
              </p>
              <p className={styles.postText}>Jsou sladidla nebezpečná?</p>
              <p className={styles.postText}>
                Sladidla bývají často <b>démonizovaná</b>. Můžete se například
                dozvědět, že{' '}
                <b>způsobují rakovinu, kardiovaskulární onemocnění</b> nebo že
                jsou <b>nebezpečnější než samotný cukr</b>. Pravda je ovšem
                taková, že sladidla používaná pro lidskou výživu musí být{' '}
                <b>zdravotně nezávadná</b>. Do potravin a nápojů se přidávají{' '}
                <b>pouze povolená sladidla </b>a jejich{' '}
                <b>množství je regulováno</b>.{' '}
              </p>
              <p className={styles.postText}>
                Každé sladidlo má <b>tolerovatelnou denní dávku</b>, čili ADI.
                Ta se musí, <b>společně se sladivostí, brát v potaz</b> při
                používání sladidel. Například ADI aspartamu je 40 mg na kilogram
                vaší váhy na den. To znamená, že pokud byste vážili 70 kg, byla
                by akceptovatelná denní dávka aspartamu 2800 mg. Dejme si{' '}
                <b>příklad</b>. Představme si 1 litr čaje oslazeného 25 gramy
                cukru. Abychom dosáhli stejné sladivosti s aspartamem, museli
                bychom do 1 litru čaje přidat 125 mg aspartamu. Abychom tedy
                dosáhli akceptovatelné denní dávky (tedy maxima, které bychom{' '}
                <b>neměli překročit) </b>museli bychom vypít 22,4 litrů takového
                čaje. Což není úplně tak běžné.
              </p>
              <p className={styles.postText}>
                Každé sladidlo má samozřejmě{' '}
                <b>jinou hodnotu ADI i jinou sladivost</b>, pokud tedy{' '}
                <b>používáte sladidla častěji</b>, měli byste si tyto hodnoty
                zjistit. Pokud si ale čas od času dáte plechovku coly zero,
                určitě nic takového nemusíte řešit.
              </p>
              <p className={styles.postText}>
                Škodí tedy sladidla našemu zdraví?
              </p>
              <p className={styles.postText}>
                Sladidla jsou v přijatelných{' '}
                denních dávkách<b> zcela bezpečná pro lidskou konzumaci</b>.
                Nicméně jsou to látky, které by se v našem jídelníčku měli
                vyskytovat spíše občasně. V zásadě bychom se měli snažit jíst{' '}
                <b>co nejméně průmyslově zpracovaných potravin</b> a k pití
                preferovat <b>čistou vodu a další neslazené nápoje</b> a obecně
                si na <b>sladkou chuť spíše odvyknout,</b> než se ji snažit
                nahradit. Pokud si však čas od času dáte nápoj či potravinu s
                náhradním sladidlem, vůbec nic se nestane.{' '}
              </p>
              <p className={styles.postText}>
                <b>TIP</b>:{' '}
                <span className={styles.author}>
                  Zdravé a vyvážené jídlo vám můžeme zajistit my! Zkuste naše
                </span>{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  krabičky
                </a>
                .
              </p>
              <p>Autorka: Ing. Kristina Heřmanová</p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default AreSweetenersHarmfulForHealth
